import React from 'react'

import SEO from 'src/components/seo'
import { Trans, withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { IN_UK } from '../utils/constants'

import {
  Container,
  Column,
  Line,
  CurrentPage,
  PageLink,
  TosSubtitle,
  Text,
  SubHeaderIndex,
  PageTitle,
  HighlightList,
  OrderedList,
  TosSubHeader,
  Highlight,
} from '../templates/ShopifyPage/styles'

const Tos = () => (
  <>
    <SEO
      title="Terms of Service"
      description="The Skylight Frame (Glimpse LLC) legal Terms of Service."
      path="/tos/"
    />
    <Container>
      <Column>
        <div>
          <Line />
          <PageLink to="/privacy-policy/">
            <Trans>Privacy Policy</Trans>
          </PageLink>
          <Line />
          <CurrentPage to="/tos/">
            <Trans>Terms of Service</Trans>
          </CurrentPage>
          <Line />
          {IN_UK && (
            <>
              <PageLink to="/psti-compliance/">
                <Trans>PSTI Statement of Compliance</Trans>
              </PageLink>
              <Line />
            </>
          )}
        </div>
      </Column>
      <Column>
        <PageTitle>
          <Trans>Terms of Service</Trans>
        </PageTitle>
        <TosSubtitle>
          <Trans i18nKey="auto_translate_tos" />
          <br />
          <br />
          <Trans>
            Glimpse LLC (doing business as "Skylight"), a Delaware Limited Liability Company
            ("Skylight", "we" or "us")
          </Trans>
          <br />
          <Trans>Last updated: December 3rd, 2024.</Trans>{' '}
          <PageLink red to="/prior-tos/">
            <Trans>Prior Version here.</Trans>
          </PageLink>
        </TosSubtitle>
        <Text>
          <Trans>
            Please read the following Terms of Service (these "Terms") carefully. These Terms form
            an agreement between You and Glimpse LLC (doing business as "Skylight"), a Delaware
            Limited Liability Company ("Skylight", "we" or "us"). THIS DOCUMENT CONTAINS VERY
            IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS,
            LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU.
          </Trans>
        </Text>
        <Text>
          <Trans>
            THESE TERMS SET FORTH BELOW "TERMS" GOVERN YOUR USE OF THE WEBSITE LOCATED AT
            WWW.SKYLIGHTFRAME.COM, OURSKYLIGHT.COM, THE SKYLIGHT APP, ANY OTHER WEBSITE, APPLICATION
            OR OTHER DIGITAL SITE THAT WE OWN, CONTROL OR OPERATE (EACH A "SITE" AND TOGETHER THE
            "SITES"), AND THE SKYLIGHT PRODUCTS INCLUDING THE FRAMES AND CALENDARS, AS WELL AS OUR
            OTHER OFFERINGS INCLUDING SIDEKICK, SKYLIGHT PLUS AND ANY OTHER PRODUCTS, SERVICES,
            SUBSCRIPTIONS OR PROPERITIES THAT SKYLIGHT MAY OFFER FROM TIME TO TIME (COLLECTIVELY,
            THE "PRODUCTS AND/OR SERVICES" AS THE CONTEXT REQUIRES). BY PLACING AN ORDER FOR
            PRODUCTS OR SERVICES FROM A SITE AND/OR REGISTERING A PRODUCT, YOU AFFIRM THAT YOU ARE
            OF LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS.
            IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE SITES OR
            THE PRODUCTS AND/OR SERVICES OR ANY INFORMATION CONTAINED HEREIN OR THEREIN. YOUR USE OF
            THIS SITE OR CLICKING "I AGREE" WHEN YOU REGISTER YOUR PRODUCT OR SIGN UP FOR A SERVICE,
            AS WELL AS THE CONTINUED USE THE PRODUCTS AND/OR SERVICES WILL BE DEEMED TO BE YOUR
            AGREEMENT TO ABIDE BY THE TERMS SET FORTH HEREIN. SKYLIGHT MAY MAKE CHANGES TO THE
            CONTENT AND THE SERVICES OFFERED ON THIS SITE AT ANY TIME. SKYLIGHT MAY CHANGE THESE
            TERMS AT ANY TIME BY POSTING UPDATED TERMS OF USE ON THE SITE(S).
          </Trans>
        </Text>
        <Text>
          <Trans>
            YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM A SITE OR FROM US IF YOU (A) DO
            NOT AGREE TO THESE TERMS, (B) ARE NOT THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii)
            LEGAL AGE TO FORM A BINDING CONTRACT WITH US, OR (C) ARE PROHIBITED FROM ACCESSING OR
            USING OUR SITES, PRODUCTS AND/OR SERVICES BY APPLICABLE LAW. YOUR USE OF THE PRODUCTS
            AND/OR SERVICES AND THE SITE(S) IS UNIQUE AND PERSONAL TO YOU AND MAY NOT BE USED FOR
            ANY COMMERCIAL PURPOSES.
          </Trans>
        </Text>
        <Text>
          <Trans>
            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN
            SECTION 20 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OR
            CLASS ARBITRATIONS OF ANY KIND.
          </Trans>
        </Text>
        <OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Order Acceptance and Cancellation.</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                You agree that your order is an offer to buy, under these Terms, all Products and
                Services listed in your order. All orders must be accepted by us, or we will not be
                obligated to sell the Products or Services to you. We may choose not to accept
                orders at our sole discretion, even after we send you a confirmation email with your
                order number and details of the items you have ordered.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Prices and Payment Terms.</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Trans>
                All prices posted on the Sites are subject to change without notice. The price
                charged for a Product or Service will be the price in effect at the time the order
                is placed and will be set out in your order confirmation. Price increases will only
                apply to orders placed after such changes. Posted prices do not include taxes or
                charges for shipping and handling. All such taxes and charges will be added to your
                order total and will be itemized in your shopping cart and in your order
                confirmation email. We are not responsible for pricing, typographical, or other
                errors in any offer by us and we reserve the right to cancel any orders arising from
                such errors.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                The Site includes Products and/or Services for purchase. Terms of payment are within
                our sole discretion and payment must be received by us before our acceptance of an
                order. We may also require that You purchase a subscription to use certain features
                of a Product and/or Services (a "Premium Membership"). By purchasing a Product, a
                Premium Membership or any other Services (each, a "Transaction"), You expressly
                authorize Skylight (or its third-party payment processor) to charge You for such
                Transaction.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                We accept credit cards and other methods of payment as listed on the checkout page.
                Skylight may ask You to provide additional information relevant to your Transaction,
                including your credit card number, the expiration date of your credit card and your
                email and postal addresses for billing and notification (such information, "Payment
                Information"). You represent and warrant that (i) the information you supply to us
                is true, correct, and complete, (ii) you are duly authorized to use such payment
                method for the purchase, (iii) charges incurred by you will be honored by your
                payment company, and (iv) you will pay charges incurred by you at the posted prices,
                and including all applicable taxes, if any. You acknowledge and agree that we have
                no liability with respect to any act or omission by your payment provider. When You
                initiate a Transaction, You authorize us to provide your Payment Information to
                third parties so we can complete your Transaction and to charge your payment method
                for the type of Transaction You have selected (plus any applicable taxes and other
                charges). You may need to provide additional information to verify your identity
                before completing your Transaction (such information is included within the
                definition of Payment Information).
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Shipments; Delivery; Title and Risk of Loss.</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Trans>
                We will arrange for shipment of the Products to you. You will pay all shipping and
                handling charges specified during the ordering process.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                Title and risk of loss pass to you upon delivery. Shipping and delivery dates are
                estimates only and cannot be guaranteed. We are not liable for any delays in
                shipments.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Registration</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                When You register your Product or for a Service, You will be asked to create your
                own profile, and additionally other portions of the Sites may provide you the
                opportunity to create a profile. Your profile includes, but is not limited to,
                personal information such as your user name, your email and general information You
                wish to disclose about yourself.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>General Use Restrictions</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Materials.</Trans>{' '}
              </Highlight>
              <Trans>
                Certain information, documents, materials and services provided on and through the
                Site(s), Products and/or Services, including content, logos, graphics and images
                (together, the "Materials") are made available to You by us and include copyrighted
                and/or trademarked work. Materials do not include Your Content (as defined below).
                Materials could include Third Party Content (as defined below) and the restrictions
                and limitations contained herein apply to Materials, whether Skylight's or Third
                Party Content.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>License.</Trans>{' '}
              </Highlight>
              <Trans>
                Skylight grants You a limited, personal, non-exclusive and non-transferable license
                to use the Materials solely for your personal use. Except for the license set forth
                in the preceding sentence above, You acknowledge and agree that You have no right to
                modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter,
                enhance or in any way exploit any of the Materials in any manner other than for your
                own personal use as part of the Products and/or Services. This limited license
                terminates automatically, without notice to You, if You breach any of these Terms.
                Upon termination of this limited license, You agree to immediately destroy any
                downloaded or printed Materials. Except as stated herein, You acknowledge that You
                have no right, title or interest in or to the Site(s), any Materials, or the
                Products and/or Services.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Restrictions.</Trans>{' '}
              </Highlight>
              <Trans>
                You are permitted to access and use the Products and/or Services and Skylight
                reserves the right to audit such use. You will be responsible for ensuring the
                security and confidentiality of all log-on identifiers, including usernames and
                passwords, assigned to, or created by, You in order to access or use the Products
                and/or Services (an "ID"). You accept that You will be solely responsible for all
                access and use of the Products and/or Services that occur under your ID and will
                promptly notify Skylight upon becoming aware of any unauthorized access to or use of
                the Products and/or Services. You will provide all reasonable assistance to Skylight
                to bring an end to such unauthorized access or use.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Use Limits.</Trans>{' '}
              </Highlight>
              <Trans>
                You agree not to misuse the Products and/or Services. For example, You must not, and
                must not attempt to, use the Products and/or Services to do the following things:
                (i) probe, scan, or test the vulnerability of any system or network; (ii) breach or
                otherwise circumvent any security or authentication measures; (iii) access, tamper
                with, or use non-public areas of the Service, shared areas of the Service You have
                not been invited to, Skylight (or our service providers') computer systems; (iv)
                interfere with or disrupt any user, host, or network, for example by sending a
                virus, overloading, flooding, spamming, or mail-bombing any part of the Products
                and/or Services; (v) plant malware or otherwise use the Products and/or Services to
                distribute malware; (vi) access or search the Products and/or Services by any means
                other than our publicly supported interfaces (for example, "scraping"); (vii) send
                unsolicited communications, promotions or advertisements, or spam; (viii) send
                altered, deceptive or false source-identifying information, including "spoofing" or
                "phishing"; (ix) publish anything that is fraudulent, misleading, or infringes
                another's rights; (x) promote or advertise products or Products and/or Services
                other than your own without appropriate authorization; (xi) impersonate or
                misrepresent your affiliation with any person or entity; (xii) publish or share
                materials that are unlawfully pornographic or indecent, or that advocate bigotry,
                religious, racial or ethnic hatred; (xiii) violate the law in any way, or to violate
                the privacy of others, or to defame others; or (xiv) modify, edit, copy, reproduce,
                create derivative works of, reverse engineer, alter, enhance or in any way exploit
                any software or hardware.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Privacy Policy</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                We respect your privacy and are committed to protecting it. Our Privacy Policy,
                https://www.skylightframe.com/privacy-policy/, governs the processing of all
                personal data collected from you in connection with your purchase of Products and/or
                Services and your interaction through the Sites, as well as through other means,
                including contacting customer service or interacting with us through our advertising
                or channels.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Content</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                As between You and Us, You will remain the owner of all rights (including all
                intellectual property rights) in all data, information or material provided by You
                or your Related Parties (as defined below) to Skylight through access to or use of
                the Products and/or Services (together "Your Content"). You grant Skylight a
                perpetual, irrevocable, non-exclusive, worldwide and royalty-free license, to use,
                reproduce, distribute, publish and publicly display the Content and the right to
                modify, adapt, translate, create derivative works from and/or incorporate the same
                into other works in any form, media or technology (whether now known or later
                developed), solely and exclusively for the purpose of providing the Products and/or
                Services to You and performing Skylight's obligations under this Agreement. You
                represent and warrant that You or your Related Party (as applicable) have all
                necessary rights, title and interest to provide to Us and to our third-party
                providers all of Your Content for use as provided in these Terms. This includes the
                right for Skylight to provide, assign and/or sub-license the same to any of its
                affiliates or service providers or sub-license to licensors, but only to the extent
                required by Skylight to provide the Products and/or Services and perform its
                obligations under this Agreement.
              </Trans>
            </Text>
            <Text>
              <Trans>
                For the avoidance of doubt, no media that you share with Skylight in accordance with
                the foregoing, and including any derivative works thereof, will be used for
                marketing or other purposes unrelated to your use of the Products and/or Services,
                without your express permission.
              </Trans>
            </Text>
            <Text>
              <Trans>
                You agree You will not, and will not allow any Related Party to, upload, post or
                otherwise make available through any Product or Service any material protected by
                copyright, trademark, or any other proprietary right without the express permission
                of the owner of such right, and the burden of determining whether any of Your
                Content is protected by any such right is on You. You will be solely liable for any
                damage resulting from any infringement of copyrights, trademarks, proprietary
                rights, or any other harm resulting from any of Your Content that You make. You
                represent and warrant that: (i) You own all of Your Content or otherwise have all
                rights necessary to provide to Skylight (via the Products and/or Services or
                Site(s), set forth in this section; and (ii) the providing of Your Content through
                the Products and/or Services do not violate the privacy rights, publicity rights,
                trademark rights, copyrights, contract rights or any other rights of any person or
                entity. You agree to pay for all royalties, fees, damages and any other monies owing
                to any person by You, your Related Parties or by Skylight or any third party
                provider in connection with Your Content.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Products and/or Services Modifications and Updates</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The Products and Services are primarily for the display of Your Content in digital
                form in Skylight products. Skylight reserves the right to make enhancements or other
                modifications to the Products (including via software updates) and/or Services from
                time to time, without materially detracting from their overall functionality.
                Skylight may allow or require you to download updates or patches onto your Skylight
                Products. Certain updates may be required to continue use of Skylight Products and
                Services. You consent to Skylight automatically installing available updates to
                Skylight Products and Services. Failure to install available updates may render
                Skylight Products and/or Services unusable.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Suspension of Services</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Skylight may temporarily suspend (in whole or part) access to the Services: (i) for
                the purpose of performing maintenance and/or of updating the Services (whether on a
                scheduled or unscheduled basis); (ii) due to a security threat or prejudicial use of
                the Services (perceived or actual); (iii) to comply with any applicable law or the
                requirement of any Internet or, if applicable, telecommunication networks service
                provider upon whom Skylight relies for the provision of the Services; (iv) to
                address, remedy or prevent any breach of this Agreement by You; or (v) to minimize
                any period of interruption and restore the Services as soon as reasonably possible,
                except where the cause of suspension arises from your breach and You have failed to
                take the necessary steps to remedy the breach.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Third Party Providers</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Trans>
                Certain information and other content appearing in the Products and/or Services may
                be the material of third party licensors, users or suppliers to Skylight ("Third
                Party Content"). Third Party Content does not include Your Content. You acknowledge
                and agree that under these terms alone You have no right to download, cache,
                reproduce, modify, display, edit, alter or enhance any of the Third Party Content in
                any manner unless You have permission from the owner of the Third Party Content.
                Third Party Content is provided "as is" without any representations or warranties.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                Third parties may also provide services that Skylight utilizes in providing the
                Products and/or Services and the Sites to You. These third parties may, for example,
                ensure that any photos sent to you by Related Parties are not suspicious or
                pornographic. These third party services are provided "as is" without any
                representations or warranties by Skylight.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                You have the ability to receive content from third parties for inclusion in the
                Products and/or Services, such as your friends and family, either by providing a
                Skylight account email to such third party, or by connecting one or more of your
                accounts with a Product or Service. Such third parties are your "Related Parties."
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Subscriptions</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Subscription Fee.</Trans>
              </Highlight>{' '}
              <Trans>
                If You purchase a subscription Service, for example the Skylight Plus membership (a
                "Subscription"), You will be charged a subscription fee, plus any applicable taxes
                and other charges ("Subscription Fee"), at the beginning of your subscription, and
                at the start of each billing period thereafter (e.g., monthly, quarterly or
                annually, whichever you have selected), at the then-current Subscription Fee amount.
                Skylight (or its third-party payment processor) will automatically charge You using
                the Payment Information You have provided until You cancel your Subscription. If You
                have a Subscription, we will send You a reminder at least three (3) days prior to
                each renewal with the then-current Subscription Fee amount. We reserve the right to
                change our Subscription Fee pricing. At our sole discretion, we may offer a free
                trial to a subscription Service, which terms and length of free trial shall be
                provided at the time of the offering; provided, that notwithstanding a zero-fee, a
                payment method may be required to receive such free trial.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Cancellation.</Trans>
              </Highlight>{' '}
              <Trans>
                You may cancel your Subscription at any time, contacting our support team at
                help@skylightframe.com and selecting or including the words "Cancel Subscription."
                Skylight will refund to You the unused, pre-paid amount of your Subscription Fee on
                a pro-rated monthly basis. You will be responsible for all Subscription Fees (plus
                any applicable taxes and other charges) incurred until Skylight receives written
                notice of your cancelation as set forth above. You may also cancel or delete your
                account with Skylight, which will also delete your data. You can export your data
                prior to cancellation in order to avoid the loss of your data.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Feedback; Beta Features; Statistics</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Trans>
                You may, during the Term, provide Skylight with feedback, whether oral and/or
                written related to your use of the Products and/or Services, or the Site(s), or any
                other Skylight product or service, including, but not limited to, a report of any
                errors which You may discover. User Content is expressly excluded from Feedback.
                Such reports, and any other materials, information, ideas, concepts, feedback and
                know-how provided by You to Skylight concerning the Products and/or Services, Site
                or any other Skylight product or service and any information reported automatically
                through the Products and/or Services to Skylight ("Feedback") will be the property
                of Skylight. You agree to assign, and do hereby assign, all rights, title and
                interest worldwide in the Feedback, and the related intellectual property rights, to
                Skylight and agree to assist Skylight, at Skylight' expense, in perfecting and
                enforcing such rights.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                We may provide to You access to certain features, functions, products or services
                that are not yet generally available, including, but not limited to, any products,
                services, features or features labeled "beta", "preview", "pre-release", or
                "experimental", and any related content ("Beta Features"). Beta Features may come
                with additional terms and conditions and if you utilize such Beta Features, you
                agree to be bound by such terms and conditions. Beta Features are provided "AS IS"
                without any representations, warranties or indemnities. We may cease providing Beta
                Features to You at any time. We may solicit specific feedback from you regarding
                your use and evaluation of Beta Features. Notwithstanding anything to the contrary
                in these Terms, after suspension or termination of your access to or use of any Beta
                Feature for any reason, (a) You will not have any further right to access or use the
                applicable Beta Feature, and (b) your data or Your Content used in the applicable
                Beta Service may be deleted or become inaccessible.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Trans>
                Notwithstanding anything contained herein to the contrary, Skylight may use
                Statistics for the modification, enhancement, and improvement of the Products and/or
                Services, and may use Statistics for the marketing or promotion of Skylight, the
                Products and/or Services or any future product or service offering, provided any
                such use of Statistics cannot reasonably be identified as your aggregate information
                alone. The term Statistics means any anonymous data, and expressly excluding
                personally identifiable data, that Skylight derives through its Site and performance
                of the Products and/or Services.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Links to Third Party Sites</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The Site may contain hyperlinks to web sites that are not controlled by Skylight.
                You acknowledge and agree that Skylight is not responsible for and does not endorse
                or accept any responsibility over the content or use of these web sites, including,
                without limitation, the accuracy or reliability of any information, data, opinions,
                advice or statements made on these web sites. You further understand that such
                third-party sites and Third Party Products and/or Services are governed by separate
                terms of use, privacy policies and similar agreements and You agree to be bound by
                the terms and conditions of such agreements. Your dealings with other entities
                promoted on or through the Products and/or Services, including payment and delivery
                of related goods or Products and/or Services, and any other terms, conditions,
                warranties or representations associated with such dealings, are solely between You
                and such other entity. You agree that Skylight will not be responsible or liable for
                any loss or damage of any sort incurred as the result of any such dealings or as the
                result of the promotion of such other entities through the Products and/or Services.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Unauthorized Activities</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Certain use of the Products and/or Services or Third Party Content, or use in
                violation of these, or related, Terms may violate certain laws and regulations. You
                agree to defend, indemnify and hold Skylight and its officers, directors, employees,
                affiliates, agents, licensors, and business partners harmless from and against any
                and all costs, damages, liabilities, and expenses (including attorneys' fees and
                costs of defense) that Skylight or any other indemnified party suffers in relation
                to, arising from, or for the purpose of avoiding, any claim or demand from a third
                party based on Your Content and/or your use of the Products and/or Services or Third
                Party Content or the use by any person, using your user name and/or password, that
                violates any applicable law or regulation, or the copyrights, trademark rights or
                other rights of any third party. Skylight may report to law enforcement authorities
                any actions that may be illegal, and any reports it receives of such conduct. When
                legally required or at Skylight' discretion, Skylight will cooperate with law
                enforcement agencies in any investigation of alleged illegal activity on this Site,
                the Products and/or Services or on the Internet.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Proprietary Rights</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Neither this Agreement nor any provision herein transfers ownership or any license
                not expressly granted herein, from Skylight to You of any Skylight intellectual
                property right of any kind whatsoever including, but not limited to, any Products
                and/or Services or the Site, and all right, title and interest in and to the
                foregoing and Skylight' legally protectable methods, processes, systems, operations,
                network or customer support methodology or technology, or any other technology of
                any kind whatsoever and all intellectual property rights therein will remain solely
                with Skylight.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Termination</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Though we'd much rather You stay, You can stop using our Products and/or Services
                anytime as further described in Section 11.2. Additionally, We reserve the right to
                suspend or end the Products and/or Services at any time, with or without cause, and
                with or without notice. For example, we may suspend or terminate your use if You are
                not complying with these Terms, or use the Products and/or Services in any way that
                would cause us legal liability or disrupt others' use of the Products and/or
                Services. If we suspend or terminate your use, we will try to let You know in
                advance and help You retrieve data, though there may be some cases (for example,
                repeatedly or flagrantly violating these Terms, a court order, or danger to other
                users) where we may suspend immediately.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Warranties</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Skylight Hardware Limited Warranty.</Trans>{' '}
              </Highlight>
              <Trans>
                Skylight may offer a warranty(ies) for its physical Product(s) and/or included
                software, which, if provided, are included in the packaging of such physical
                Product(s). Such warranties are for the benefit of the original purchaser only.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Refund and Replacements Policy as Limited Remedy.</Trans>{' '}
              </Highlight>
              <Trans>
                As your sole and exclusive remedy to a breach of any warranty express or implied, in
                the event that You are not fully satisfied with the Product or you are having
                technical difficulties with the Product, we offer the option for a replacement or
                full refund within 120 days of purchase. If You prefer a refund, we may request that
                the Product be returned to us within 30 days of such request. Our customer service
                team will provide a pre-paid shipping label so you do not incur additional costs.
                After 120 days of purchase, we will consider replacements and refunds on a
                case-by-case basis.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Disclaimer.</Trans>{' '}
              </Highlight>
              <Trans>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, ALL IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE PRODUCTS THAT CANNOT BE
                DISCLAIMED ARE LIMITED TO THE DURATION OF THE APPLICABLE EXPRESS WARRANTY. ALL OTHER
                EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING (BUT NOT
                LIMITED TO) ANY IMPLIED WARRANTY OF NON-INFRINGEMENT, ARE DISCLAIMED TO THE MAXIMUM
                EXTENT PERMITTED BY LAW. Some jurisdictions do not allow limitations on how long an
                implied warranty lasts, so the above limitation may not apply to You. This warranty
                gives You specific legal rights, and You may also have other rights which vary by
                jurisdiction and which are not varied by this clause to the extent that doing so
                would breach such applicable law.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Disclaimer of Warranty for Services.</Trans>{' '}
              </Highlight>
              <Trans>
                Skylight will use commercially reasonable efforts to correct any discovered defects
                in the Services. However, to the maximum extent permitted by applicable law, Your
                access to and use of the Services is at your own risk. We are not responsible for
                the content provided by, or the conduct of, any user and You bear the entire risk of
                using the Services and any interactions with other users. Our Services are provided
                on an "AS IS" and "AS AVAILABLE" basis and, to the maximum extent permitted by
                applicable law, we do not represent, warrant, or guarantee that the Services will be
                provided uninterrupted, error-free, virus-free, or that defects will be corrected.
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES
                REGARDING THE SERVICES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE
                WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. If applicable law requires any warranties with respect to our
                Services, all such warranties are limited in duration to the longer of thirty (30)
                days from the date of your first use or the minimum duration allowed by law.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Disclaimer of Third Party Content and Related Party content.</Trans>{' '}
              </Highlight>
              <Trans>
                Disclaimer of Third Party Content and Related Party content. Skylight expressly
                disclaims all warranties relating to any Third Party Content and any content
                provided by any Related Party, which is provided, "as is". You accept all risk with
                respect to such content.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Limitation of Liability</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Exclusion of Consequential Damages and Limit of Liability.</Trans>{' '}
              </Highlight>
              <Trans>
                EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW, SKYLIGHT WILL NOT BE RESPONSIBLE
                FOR ANY LOST PROFITS, REVENUES, OR DATA, COST OF SUBSTITUTE GOODS OR PRODUCTS AND/OR
                SERVICES, OR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES,
                HOWEVER CAUSED AND REGARDLESS OF THE THEORY OF LIABILITY, RELATED TO OR ARISING OUT
                OF THESE TERMS OR YOUR USE OF THE PRODUCTS, OR PRODUCTS AND/OR SERVICES, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY
                LAW, OUR TOTAL LIABILITY FOR ANY CLAIM RELATED TO OR ARISING OUT OF THESE TERMS OR
                YOUR USE OF THE PRODUCTS OR PRODUCTS AND/OR SERVICES, IS LIMITED TO THE GREATER OF
                (A) THE AMOUNT YOU HAVE PAID TO SKYLIGHT FOR THE PRODUCT GIVING RISE TO THE CLAIM,
                (B) THE AMOUNT YOU HAVE PAID TO SKYLIGHT FOR YOUR SKYLIGHT PLUS MEMBERSHIP GIVING
                RISE TO THE CLAIM IN THE IMMEDIATE TWELVE (12) MONTHS PRECEDING THE DATE THE CLAIM
                AROSE, OR (C) ONE HUNDRED FIFTY-NINE U.S. DOLLARS (US$159).
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Allocation of Risk.</Trans>{' '}
              </Highlight>
              <Trans>
                THE PROVISIONS OF THIS AGREEMENT ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE
                PARTIES AND ARE AN INTRINSIC PART OF THE BARGAIN BETWEEN THE PARTIES. THE FEES
                PROVIDED FOR IN THIS AGREEMENT REFLECT THIS ALLOCATION OF RISKS AND THE LIMITATION
                OF LIABILITY AND SUCH LIMITATION SHALL APPLY NOTWITHSTANDING A FAILURE OF THE
                ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Goods Not for Resale or Export.</Trans>{' '}
            </TosSubHeader>
            <Text>
              <Trans>
                You represent and warrant that you are buying Products and/or Services for your own
                personal or household use only, and not for resale or export.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Dispute Resolution and Binding Arbitration Provision.</Trans>{' '}
            </TosSubHeader>
            <OrderedList>
              <HighlightList>
                <Trans>
                  YOU AND SKYLIGHT ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT
                  OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION
                  WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY
                  ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION. ANY CLAIM, DISPUTE, OR
                  CONTROVERSY (WHETHER IN CONTRACT, TORT, OR OTHERWISE, WHETHER PRE-EXISTING,
                  PRESENT, OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW,
                  INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM
                  OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE SITE,
                  WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
                </Trans>
              </HighlightList>
              <HighlightList>
                <Trans>
                  The arbitration will be administered by the American Arbitration Association
                  ("AAA") in accordance with the Consumer Arbitration Rules (the "AAA Rules") then
                  in effect, except as modified by this Section 20. (The AAA Rules are available at
                  www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal
                  Arbitration Act will govern the interpretation and enforcement of this section.
                </Trans>
              </HighlightList>
              <HighlightList>
                <Trans>
                  The arbitrator will have exclusive authority to resolve any dispute relating to
                  arbitrability and/or enforceability of this arbitration provision, including any
                  unconscionability challenge or any other challenge that the arbitration provision
                  or the agreement is void, voidable, or otherwise invalid. The arbitrator will be
                  empowered to grant whatever relief would be available in court under law or in
                  equity. Any award of the arbitrator(s) will be final and binding on each of the
                  parties and may be entered as a judgment in any court of competent jurisdiction.
                </Trans>
              </HighlightList>
              <HighlightList>
                <Trans>
                  If you prevail on any claim that affords the prevailing party attorneys' fees, the
                  arbitrator may award reasonable fees to you under the standards for fee shifting
                  provided by law.
                </Trans>
              </HighlightList>
              <HighlightList>
                <Trans>
                  You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU
                  NOR SKYLIGHT WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER
                  CUSTOMERS IN COURT OR ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS
                  REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The
                  arbitral tribunal may not consolidate more than one person's claims and may not
                  otherwise preside over any form of a representative or class proceeding. The
                  arbitral tribunal has no power to consider the enforceability of this class
                  arbitration waiver and any challenge to the class arbitration waiver may only be
                  raised in a court of competent jurisdiction.
                </Trans>
              </HighlightList>
              <HighlightList>
                <Trans>
                  If any provision of this arbitration agreement is found unenforceable, the
                  unenforceable provision will be severed, and the remaining arbitration terms will
                  be enforced.
                </Trans>
              </HighlightList>
            </OrderedList>
            <Text bold>
              <Trans>Skylight Customer Service Center Address</Trans>
            </Text>
            <Text>
              <Trans>Glimpse LLC (dba Skylight)</Trans>
            </Text>
            <Text>
              <Trans>ATTN: LEGAL/ARBITRATION</Trans>
            </Text>
            <Text>
              <Trans>101A Clay St #144</Trans>
            </Text>
            <Text>
              <Trans>San Francisco, California 94111</Trans>
            </Text>
            <Text>
              <Trans>help@skylightframe.com</Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Force Majeure</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Neither party will be liable to the other for any breach of this Agreement which
                arises because of any circumstances which the defaulting party cannot reasonably be
                expected to control (which shall include but not be limited to, any act of god,
                fire, casualty, flood, war, labor disputes, governmental action, failure of public
                services, interruption or failure of the Internet or of any network,
                telecommunications, power supply or infrastructure, or any provider of any of the
                foregoing but, for the avoidance of doubt, shall not include shortage or lack of
                available funds, provided it promptly notifies the other party of such
                circumstances. If performance has been interfered with, hindered, delayed or
                prevented for a period in excess of ninety (90) days, the party whose performance is
                not affected may terminate this Agreement immediately by written notice to the other
                party.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>General</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                These Terms constitute the entire and exclusive agreement between You and Skylight
                with respect to the Products and/or Services, and supersede and replace any other
                agreements, terms and conditions applicable to the Products and/or Services. These
                Terms create no third party beneficiary rights. Skylight's failure to enforce a
                provision is not a waiver of its right to do so later. If a provision is found
                unenforceable the remaining provisions of the Agreement will remain in full effect
                and an enforceable term will be substituted reflecting our intent as closely as
                possible. You may not assign any of your rights in these Terms, and any such attempt
                is void, but Skylight may assign its rights to any of its affiliates or
                subsidiaries, or to any successor in interest of any business associated with the
                Products and/or Services. Skylight and You are not legal partners or agents;
                instead, our relationship is that of independent contractors. The Sites are operated
                from the US. All matters arising out of or relating to these Terms are governed by
                and construed in accordance with the internal laws of the State of California
                without giving effect to any choice or conflict of law provision or rule (whether of
                the State of California or any other jurisdiction) that would cause the application
                of the laws of any jurisdiction other than those of the State of California.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Electronic Communications</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The information communicated on this Website constitutes an electronic
                communication. When You communicate with Skylight through the Website or other forms
                of electronic media, such as e-mail, You are communicating with Skylight
                electronically. You agree that Skylight, on behalf of itself and others who may be
                involved with delivering the Products and/or Services (as applicable), may
                communicate electronically by e-mail and/or may make communications available to You
                by posting them on the Website, and that such communications, as well as notices,
                disclosures, agreements and other communications that we provide to You
                electronically, are equivalent to communications in writing and shall have the same
                force and effect as if they were in writing and signed by Skylight. Notices and
                communications to Skylight must be sent to the applicable address given in these
                Terms or to; help@skylightframe.com.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Notice for California Users</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Under California Civil Code Section 1789.3, California users of the Site are
                entitled to the following specific consumer rights notice: The headquarters of
                Glimpse LLC, d/b/a/ Skylight is currently located at 101A Clay St #144, San
                Francisco, California 94111, USA, phone (415) 233-6761. The charges for the Products
                and/or Services are specified on the Site. If You have a complaint regarding the
                Products and/or Services or want to request a paper copy of these Terms, please
                contact Skylight by writing to the address above, or by e-mail at
                help@skylightframe.com, or by calling (415) 233-6761. The Consumer Information
                Center of the Department of Consumer Affairs may be contacted in writing at 1625
                North Market Blvd., Suite N-112, Sacramento, CA 95384, or by telephone at (916)
                445-1254 or (800) 952-5210.
              </Trans>
            </Text>
          </SubHeaderIndex>
        </OrderedList>
      </Column>
    </Container>
  </>
)

export default withTranslation()(Tos)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "tos"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
